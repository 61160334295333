import React from 'react';
import Layout from '../../components/Layout/layout';
import MaternityPayCalculator from '../../Views/Portal/Tools/Calculators/Maternity-Pay-Calculator/Maternity-Pay-Calculator';

const MaternityPayCalculatorPage: React.FC = () => {
  return (
    <Layout>
      <MaternityPayCalculator />
    </Layout>
  );
};

export default MaternityPayCalculatorPage;
